import React from 'react';
import { TableOfContentsLayoutTestId } from './constants';
import TableOfContents from '../TableOfContents';
import { renderRichText } from '../../core/renderRichText';

export interface Props {
  body: any;
  TOCTitle?: string;
}

export const TableOfContentsLayout: React.FC<Props> = ({ body, TOCTitle }) => {
  return (
    <section data-testid={TableOfContentsLayoutTestId} className="container py-5">
      <div className="row">
        <div className="col-lg-3 col-12 d-none d-lg-block">
          <div className="sticky-topbar">
            <div className="mb-4">
              {TOCTitle && <h5 className="mb-3">{TOCTitle}</h5>}
              <TableOfContents body={body} />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-9" style={{ lineHeight: '169.52%' }}>
          {renderRichText(body)}
        </div>
      </div>
    </section>
  );
};

export default TableOfContentsLayout;
